import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import React, { useCallback } from "react";
import Page from "../components/Page";

import styled from "styled-components";
import { vwMobile, vwDesktop, rem } from "../helpers/styles";
import { BREAKPOINTS } from "../helpers/theme";

import { getTranslations } from "../helpers/translations";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { isPublished } from "../helpers/directus";

const NotFoundPage = ({ data }: { data: any }) => {
  const intl = useIntl();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.not_found_404_page.translations",
      intl.locale
    );
  }, [data]);

  const get404Data = useCallback(() => {
    const translatedData = getRootTranslatedData();

    if (!isPublished(translatedData?.status)) return null;

    return translatedData?.content;
  }, [getRootTranslatedData, intl]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
      displayFooter={true}
      displayMailing={false}
    >
      <Container>
        <Content
          dangerouslySetInnerHTML={{
            __html: get404Data(),
          }}
        />
      </Container>
    </Page>
  );
};

export default NotFoundPage;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${vwMobile(40)} ${vwMobile(20)};
  height: 100vh;
  background-color: #033305;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${vwDesktop(233)} ${vwDesktop(310)} ${vwDesktop(388)};
  }
`;

const Content = styled.div`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  text-align: center;

  h1 {
    font-size: ${rem(120)};
    font-weight: 900;
    letter-spacing: 0.04em;
    line-height: ${rem(74)};
    margin-bottom: ${vwMobile(50)};

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${vwDesktop(50)};
    }
  }

  h2 {
    font-size: ${rem(36)};
    font-weight: 900;
    letter-spacing: 0.03em;
    line-height: ${rem(40)};
    margin-bottom: ${vwMobile(20)};

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${vwDesktop(20)};
    }
  }

  p {
    font-size: ${rem(18)};
    font-weight: 600;
    letter-spacing: 0;
    line-height: ${rem(28)};
    margin-bottom: ${vwMobile(20)};

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${vwDesktop(20)};
    }
  }

  a {
    color: #ffffff;
    font-weight: 800;
  }
`;

export const query = graphql`
  query ($locale: String) {
    directus {
      not_found_404_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          id
          content
          languages_code {
            name
            code
          }
          status
        }
      }
    }
  }
`;
